import React, { useState } from "react"
import { Navigator } from "./LanguageHelpers/Navigator"
import { AiOutlineRight, AiOutlineArrowRight } from "react-icons/ai"

const CtaSecondary = ({ cta, recordId }) => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(true)
  }

  const onLeave = () => {
    setHover(false)
  }
  return (
    <Navigator
      recordId={recordId}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      type="button"
      className="flex items-center justify-center gap-1 rounded-[50px] bg-primary-100 px-3 py-2 font-semibold text-primary-600 transition hover:bg-primary-200 sm:px-6 sm:py-4 "
    >
      <span> {cta}</span>
      {hover ? <AiOutlineArrowRight /> : <AiOutlineRight />}
    </Navigator>
  )
}

export default CtaSecondary
