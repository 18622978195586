import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { usePageLanguage } from "../hooks/usePageLanguage"
import { Navigator } from "./LanguageHelpers/Navigator"
const ContentSlider = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsSlider {
        sliderNodes: nodes {
          id
          title
          locale
          slides {
            link {
              ... on DatoCmsElevator {
                id: originalId
                title
                tag
                hero {
                  gatsbyImageData(height: 190)
                  url
                }
              }
              ... on DatoCmsElectronic {
                title
                id: originalId
                tag
                hero {
                  gatsbyImageData(height: 190)
                  url
                }
                categoryElectronic {
                  title
                }
              }
            }
          }
        }
      }
    }
  `)
  const {
    allDatoCmsSlider: { sliderNodes },
  } = data

  const { pageLanguage } = usePageLanguage()
  const SliderNodesMatch = sliderNodes.filter(
    ({ locale }) => locale === pageLanguage
  )

  return (
    <div className="mx-auto  flex w-full max-w-screen-xl flex-col items-start justify-start space-y-4 px-4 py-8">
      <h2 className="text-3xl font-bold text-primary-600 ">
        {SliderNodesMatch[0].title}
      </h2>
      <div className="h-full w-full overflow-y-hidden ">
        <div className="grid  grid-cols-[repeat(4,1fr)] gap-4 overflow-x-auto">
          {SliderNodesMatch[0].slides.map((slide, index) => (
            <Navigator
              className="group  transition-all "
              key={index}
              recordId={slide.link.id}
            >
              <div className="flex h-full flex-1 cursor-pointer flex-col justify-start">
                {slide.link.hero && slide.link.hero.gatsbyImageData ? (
                  <div className="flex min-h-[200px] w-full flex-1 items-center justify-center bg-lightGray p-5 transition-all group-hover:opacity-80">
                    <GatsbyImage
                      alt={slide.link.title}
                      image={slide.link.hero.gatsbyImageData}
                    />
                  </div>
                ) : (
                  <img
                    className="group-hover:opacity-80"
                    alt={slide.link.title}
                    src={slide.link.hero.url}
                  />
                )}
                <div className="text-darkprimary flex w-full flex-col items-start justify-start space-y-2 overflow-hidden  whitespace-nowrap p-2">
                  <h3 className="text-xl font-semibold">{slide.link.title}</h3>
                  <p className="text-ellipsis font-medium">{slide.link.tag}</p>
                  {slide.link.categoryElectronic?.title ? (
                    <span className="font-mono text-sm font-medium">
                      {slide.link.categoryElectronic?.title}
                    </span>
                  ) : (
                    <span>Elevator</span>
                  )}
                </div>
              </div>
            </Navigator>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContentSlider
