import { graphql } from "gatsby"
import * as React from "react"

import ContentSlider from "../components/ContentSlider"
import Features from "../components/features"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import TwoColumnProduct from "../components/TwoColumnProduct"
const HomePage = ({
  pageContext,
  data: {
    datoCmsHomepage: {
      heroContent: [{ tag, title, backgroundImage }],
      heroSection: [{ cta, content, ctaSecondary }],

      seo,
      features,
      electronics,
    },
    contact,
    electronic,
  },
}) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "MoviLift",
    url: "https://www.movilift.com",
    description: seo?.seo?.seoDescription,
    logo: seo?.seo?.image?.seoImageUrl,
    sameAs: [
      "https://www.facebook.com/movilift/",
      "https://www.instagram.com/moviliftitalia/",
    ],
  }
  return (
    <Layout
      pageData={pageContext}
      seoTitle={seo?.seo?.seoTitle}
      seoDescription={seo?.seo?.seoDescription}
      seoImage={seo?.seo?.image?.seoImageUrl}
      schemaMarkup={schema}
    >
      <Hero
        tag={tag}
        title={title}
        structuredData={content}
        recordIdPrimary={electronic.id}
        recordIdSecondary={contact.id}
        ctaPrimary={cta}
        ctaSecondary={ctaSecondary}
        bgImage={backgroundImage.gatsbyImageData}
      />
      <ContentSlider />
      <Features features={features} />
      <TwoColumnProduct
        title={electronics[0].title}
        description={electronics[0].description}
        heroimage={electronics[0].image.gatsbyImageData}
        cta={electronics[0].cta}
        linkId={electronic.id}
        structuredData={electronics[0].content}
        reverse
      />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      locale
      id: originalId
      heroContent: heroContent {
        tag
        title
        backgroundImage {
          gatsbyImageData
        }
      }
      heroSection: heroSection {
        description
        cta
        ctaSecondary
        content {
          value
        }
        image {
          gatsbyImageData(height: 350)
        }
      }
      features {
        id
        description
        title
        icon {
          gatsbyImageData(height: 350)
          url
        }
        content {
          value
          links {
            __typename
            id: originalId
            title
            slug
          }
        }
      }
      electronics: section {
        id
        cta
        title
        description
        content {
          value
        }
        image {
          gatsbyImageData(height: 350)
        }
      }
      seo: seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }

    contact: datoCmsContactPage(locale: { eq: $locale }) {
      id: originalId
    }
    electronic: datoCmsElectronicPage(locale: { eq: $locale }) {
      id: originalId
    }
  }
`
