import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { renderRule, StructuredText } from "react-datocms"
import {
  isParagraph,
  isHeading,
} from "react-datocms/node_modules/datocms-structured-text-utils"
import CtaPrimary from "./CtaPrimary"
import CtaSecondary from "./CtaSecondary"
const Hero = ({
  tag,
  title,
  structuredData,
  ctaPrimary,
  ctaSecondary,
  recordIdPrimary,
  recordIdSecondary,
  bgImage,
}) => {
  return (
    <div className="bg-lightGray">
      <div className="mx-auto max-w-screen-xl   px-4">
        <div
          className={` mx-auto flex  max-w-3xl flex-col items-center  justify-center py-6 `}
        >
          <div className=" flex w-full  flex-col items-center justify-center gap-4  ">
            <div className="flex flex-col items-center justify-center">
              <span className="text py-2 font-mono font-semibold  uppercase tracking-widest text-primary-700 ">
                {tag}
              </span>
              <h1 className=" bg-gradient-to-r from-primary-500 to-primary-700 bg-clip-text text-center text-4xl font-extrabold leading-tight tracking-tighter text-transparent sm:text-5xl lg:text-6xl ">
                {title}
              </h1>
            </div>

            <div className="mt-4 flex w-full max-w-[250px] flex-col items-stretch justify-center gap-4  sm:max-w-full sm:flex-row sm:items-center    ">
              <CtaPrimary recordId={recordIdPrimary} cta={ctaPrimary} />
              <CtaSecondary recordId={recordIdSecondary} cta={ctaSecondary} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
